import { UniqueCampaignOutput, VacanciesOutput } from '../../../../interfaces/InterfaceCampaign';
import { SelectInput } from '../../../SelectInput';
import { Container, Line } from '../DonationItems/styles';
import { useState, useEffect } from 'react';

interface DonationVoluntaryProps {
  selectedCause: UniqueCampaignOutput | undefined;
}

export const DonationVoluntary: React.FC<DonationVoluntaryProps> = ({ selectedCause, }) => {

  const [selectedVacancieId, setSelectedVacancieId] = useState<number | undefined>(undefined);
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const VacancieId = parseInt(event.target.value);
    setSelectedVacancieId(VacancieId);
  };
  const selectedVacancie: VacanciesOutput | undefined = selectedCause?.vacancies.find((vacancie) => vacancie.id === selectedVacancieId);


  class MyDate {
    static formatISOString(isoDate: string) {
      const [year, month, rest] = isoDate.split('-');
      const [day] = rest.split('T');
      return `${day}/${month}/${year}`;
    }
  }

  useEffect(() => {
    if (selectedCause?.items.length) {
      setSelectedVacancieId(selectedCause.vacancies[0].id);
    }
  }, [selectedCause]);

  return (
    <>
      <Container.Input>
        <Container.Text>
          <SelectInput
            htmlFor="vacancieId"
            name="vacancieId"
            id="vacancieId"
            value={selectedVacancieId !== undefined ? selectedVacancieId.toString() : ''}
            onChange={handleSelectChange}
            required
            options={[
              { value: '', label: '' },
              ...(selectedCause?.vacancies?.map((vacancie) => ({
                value: vacancie.id.toString(),
                label: vacancie.title,
              })) || []),
            ]}
          >
            Vagas
          </SelectInput>
          <Container.Address style={{ width: '100%', paddingTop: '16px', paddingBottom: '16px' }}>
            <span>Informações </span>
            Endereço: {selectedVacancie?.address} ,
            <p>
              {selectedVacancie?.city}, {selectedVacancie?.state}
            </p>
            <Line className="line" />
            {selectedVacancie?.startDate && (
              <p>Data de início: {MyDate.formatISOString(selectedVacancie?.startDate)}</p>
            )}
            {selectedVacancie?.endDate && (
              <p>Data de fim: {MyDate.formatISOString(selectedVacancie?.endDate)}</p>
            )}
            <p>
              Hora: {selectedVacancie?.startHour} às {selectedVacancie?.endHour}{' '}
            </p>
            <Line className="line" />
            Vagas: {selectedVacancie?.quantity}
            <p>Voluntários Inscritos: {selectedVacancie?.subscribersCount} </p>
          </Container.Address>
        </Container.Text>
        <Container.Text>
          <Container.Address >
            <span>Descrição </span>
            {selectedVacancie?.description},
          </Container.Address>
        </Container.Text>
      </Container.Input>
    </>
  );
};
