import { Container } from './styles'
import { Link } from 'react-router-dom'
import { LayoutModal } from './component/LayoutModal'
import { useState } from 'react'
import doe_money from '../../../assets/icones/doe_money.svg'
import doe_items from '../../../assets/icones/doe_items.svg'
import doe_voluntary from '../../../assets/icones/doe_voluntary.svg'

import location from '../../../assets/icones/location.svg'

interface Props {
  id: string
  title: string
  image: string
  summary: string
  category: string
  city: string
  state: string
  progress: number
  donations: DonationsOutput
}

interface DonationsOutput {
  money: boolean
  items: boolean
  voluntary: boolean
}

export const Campaign = ({
  image,
  title,
  summary,
  id,
  city,
  state,
  donations,
  progress
}: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedCause] = useState<Props | undefined>(
    undefined
  )
  return (
    <Container.box>
      <Container.cards>
        <Link to={`/causes/${id}`}>
          <Container.image>
            <img src={image} alt="cause" />
          </Container.image>
          <Container.text>
            <h3>
              {title && title.length > 30
                ? title.substring(0, 30) + '...'
                : title}
            </h3>
            <Container.location>
              <img src={location} alt="cidade" />
              <span>
                {city && city.length > 35 ? city.substring(0, 45) + '...' : city}
              </span>
              <span>
                {state && state.length > 2 ? state.substring(0, 2) + '...' : state}
              </span>
            </Container.location>
            <p>
              {summary && summary?.length > 255
                ? summary.substring(0, 255) + '...'
                : summary}
            </p>
          </Container.text>
        </Link>
        <Container.content>
          <Container.icons>
            {donations.money ? <img src={doe_money} alt="Arrecadação" title="Arrecadação" /> : null}
            {donations.items ? <img src={doe_items} alt="Doação" title="Doação" /> : null}
            {donations.voluntary ? (
              <img src={doe_voluntary} alt="Voluntariado" title="Voluntariado" />
            ) : null}
          </Container.icons>
        </Container.content>
        <Container.Progress>
          <span>{progress ?? 0}%</span>
          <div className="progress">
            <div className="barprogress" style={{ width: `${progress}%` }}></div>
          </div>
        </Container.Progress>
        <Container.footer>
          <Link to={`/causes/${id}`}>
            <Container.button
            >
              Apoiar Causa
            </Container.button>
          </Link>
        </Container.footer>
        <LayoutModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          selectedCause={selectedCause}
        />
      </Container.cards>
    </Container.box>
  )
}
