import { Develop } from '../components/common/Develop'
import { ListCardsCause } from '../components/common/ListCardsCause'
import { Navbar } from '../components/common/Navbar'

export const Campaigns = () => {
  return (
    <>
      <Navbar />
      <ListCardsCause showPagination={true} />
      {/* <Footer /> */}
      <footer style={{ marginTop: '1rem' }}>
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          <Develop />
        </div>
      </footer>
    </>
  )
}
