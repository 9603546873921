import styled from 'styled-components'
import theme from '../../../themes/theme'

const Container__Search = styled.form`
display: flex;
padding-right: 8px;
  label {
    padding: 10px 0;
    border: 1px solid ${(props) => props.theme.colors.lightgray};
    border-radius: 4px;
    width: 100%;
  }
  input {
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.gray};
    border-radius: 4px;
    width: 90%;

  }
  button {
    padding: 0 6px;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  @media (max-width: ${theme.media.mobile}) {
    padding-right: 0;
  }

`

export const Container = {
  Search: Container__Search
}
