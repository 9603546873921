import styled from 'styled-components'

const Container__Main = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0px;
  left: 0px;
`

const Container__Modal = styled.div`
  max-width: 50%;
  background: white;
  padding: 30px 40px;
  border-radius: 20px;
  transition: all 300ms linear 0s;
  box-shadow: 0px 20px 30px rgb(153 153 153 / 40%);
  @media (max-width: 480px) {
    max-width: 100%;
  }
`

const Container__Content = styled.div`
  border-radius: 4px;
  position: relative;
`

const Container__title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-bottom: 15px;
`

const Container__Border = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 1px black;
`
export const Container = {
  main: Container__Main,
  modal: Container__Modal,
  content: Container__Content,
  title: Container__title,
  border: Container__Border
}

const Title__Pay = styled.p`
  :hover {
    scale: 1.05;
    font-weight: 600;
  }
`
const Title__Donate = styled(Title__Pay)``
const Title__Voluntary = styled(Title__Pay)``

export const Title = {
  pay: Title__Pay,
  donate: Title__Donate,
  voluntary: Title__Voluntary
}

export const SocialIcon = styled.div`
  display: flex;
  justify-content: flex-end;
`
