import { _SelectInput, _SelectInputAuth } from "./styles";
import { StyledComponent } from "styled-components";

interface Option {
  value: string | number;
  label: string;

}
interface SelectInputProps {
  htmlFor?: string;
  id: string;
  children?: React.ReactNode;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string | number;
  placeholder?: string;
  "aria-labelledby"?: string;
  required?: boolean;
  options: Option[];
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  StyledInput: StyledComponent<"select", any, any, never>
}

export const SelectInputBase =
  ({ id, htmlFor, children, value,
    onChange, name, placeholder, options, error, StyledInput, ...props }: SelectInputProps) => {

    return (
      <StyledInput>
        <label htmlFor={htmlFor} >
          <select
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            {...props}
            aria-labelledby={htmlFor}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <span>{children}</span>
        </label>
        <div className="error">{error}</div>
      </StyledInput>
    );
  };

export const SelectInput = ({
  id,
  htmlFor,
  children,
  name,
  value,
  onChange,
  placeholder,
  options,
  error,
  StyledInput = _SelectInput,
  ...props
}: Omit<SelectInputProps, "StyledInput"> & { StyledInput?: StyledComponent<"select", any, any, never> }) => {

  return (
    <StyledInput>
      <label htmlFor={htmlFor} >
        <select
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...props}
          aria-labelledby={htmlFor}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span>{children}</span>
      </label>
      <div className="error">{error}</div>
    </StyledInput>
  );
};


export const SelectInputAuth = ({
  id,
  htmlFor,
  children,
  name,
  value,
  onChange,
  placeholder,
  options,
  error,
  StyledInput = _SelectInputAuth,
  ...props
}: Omit<SelectInputProps, "StyledInput"> & { StyledInput?: StyledComponent<"select", any, any, never> }) => {

  return (
    <StyledInput>
      <label htmlFor={htmlFor} >
        <select
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...props}
          aria-labelledby={htmlFor}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span>{children}</span>
      </label>
      <div className="error">{error}</div>
    </StyledInput>
  );
};
