import { Container, Text } from './styles';
import logo_coloridos from '../../../assets/logo/logo_coloridos.png'

export const Slider = () => {
  return (
    <Container.main>
       <Container.text>
        <Container.box>
          <img src={logo_coloridos} alt="logo" />
          <Container.wrapper>
            <Text.desc>
            Você pode escolher como apoiar o Instituto Ronald McDonald e ainda ganhar uma recompensa por isso!
            Seja pela doação ao Movimento Aliança Pela Vida ou sendo voluntário durante o McDia Feliz, seu ato
            de generosidade vale muito!.
              {/* <Container.button><button> <a href="/#doGoodDay">Saiba Mais</a></button></Container.button> */}
            </Text.desc>
          </Container.wrapper>
        </Container.box>
      </Container.text>
    </Container.main>
  )
}
