import { Container } from './styles'

import logo_gooders from '../../../assets/logo/logo_gooders.svg'
import heart from '../../../assets/icones/heart.svg'

export const Develop: React.FC = () => {
  return (
    <Container>
      <p style={{display: "flex", gap: ".25rem"}}>2023 <a href="https://gooders.com.br/" target={'_blank'} rel="noreferrer">Gooders</a> All rights reserved <img
          style={{ width: '60px', marginLeft: ".25rem" }}
          src={logo_gooders}
        ></img></p>
      <p>
        Desenvolvido com <img src={heart} alt="heart" /> por {''}
        <a href="https://ifdot.com.br/" target={'_blank'} rel="noreferrer">
          IFdot.
        </a>
      </p>
    </Container>
  )
}
