import { Container, Button, Input, Logo } from './styles'
import IonIcon from '@reacticons/ionicons'
import g_orange_logo_fill from '../../../../../../assets/logo/g_orange_logo_fill.svg'
import { useEffect, useState } from 'react'
import { axiosClient } from '../../../../../config/axios'

interface Props {
  cause?:
  | {
    id: string
    title: string
    image: string
    summary: string
  }
  | undefined
}

export const ModalDonation: React.FC<Props> = ({ cause }) => {
  const [donation, setDonation] = useState('')
  const [quantity, setQuantity] = useState('')
  const [causeData, setCauseData] = useState<{
    id: string
    image: string
  } | null>(null)

  useEffect(() => {
    if (cause) {
      try {
        axiosClient
          .get(`/data/causes/${cause.id}`)
          .then((response) =>
            setCauseData({ id: response.data.id, image: response.data.image })
          )
      } catch (err) {
        console.log(err)
      }
    }
  }, [cause])

  const handleDonationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDonation(e.target.value)
  }

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(e.target.value)
  }

  const handleDonateClick = () => {
    console.log(`Doação: ${donation}, Quantidade: ${quantity}`)
  }
  return (
    <>
      <Container.input>
        <Container.text>
          <Input.donate>
            <input
              type="text"
              placeholder="Qual a sua doação..."
              value={donation}
              onChange={handleDonationChange}
              required
            />
            <p>Objeto de Doação</p>
          </Input.donate>
          <Input.hours>
            <input
              type="text"
              placeholder="Quantos são..."
              value={quantity}
              onChange={handleQuantityChange}
              required
            />
            <p>Quantidade</p>
          </Input.hours>
        </Container.text>
        <Container.image>
          <Logo>
            <img src={g_orange_logo_fill} alt="logo" />
          </Logo>
          {causeData && <img src={causeData.image} alt="event" />}
          <p>
            {' '}
            50 Unid <IonIcon name="arrow-forward-outline"></IonIcon> 300 gooders
          </p>
        </Container.image>
      </Container.input>
      <Button onClick={handleDonateClick}>Finalizar Doação</Button>
    </>
  )
}
