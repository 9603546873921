import { useEffect, useMemo, useState } from 'react'
import { Container, Description } from './styles'
import { Link, useParams } from 'react-router-dom'
import { axiosClient } from '../../config/axios'
import locality from '../../../assets/icones/location.svg'
import { UniqueCampaignOutput } from '../../interfaces/InterfaceCampaign'
import { ImageCampaign } from './components/ImageCampaign'
import share from '../../../assets/icones/share.svg'
import { DonationItems } from './components/DonationItems'
import { DonationVoluntary } from './components/DonationVoluntary'

enum ActiveTabEnum {
  'ITEMS',
  'VOLUNTARY',
}

export const CauseUnit: React.FC = () => {

  const location = useParams()

  const [selectedCause, setSelectedCause] = useState<UniqueCampaignOutput | undefined>(
    undefined
  )
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.ITEMS);

  const handleAccumulatedValue = useMemo(() => {
    const accumulatedValue = selectedCause?.moneyDonationOptions?.accumulated;
    if (accumulatedValue) {
      return (accumulatedValue / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    } else {
      return 'R$ 0,00';
    }
  }, [selectedCause?.moneyDonationOptions?.accumulated]);

  useEffect(() => {
    try {
      axiosClient
        .get<{ data: UniqueCampaignOutput }>(`/campaigns/unique/${location.path}`)
        .then((response) => setSelectedCause(response.data.data))

    } catch (err) {
      console.log(err)
    }
    window.scrollTo(0, 0);
  }, [])
  const handleShare = () => {
    navigator.share({
      title: document.title,
      text: 'Gooders',
      url: window.location.href,
    });
  };

  const Url = () => {
    if (window.location.hostname !== "localhost") {
      return `https://app.gooders.com.br/login?next=campanhas/${location.path}`
    } else {
      return `https://dev.ifdot.com.br/login?next=campanhas/${location.path}`
    }
  }

  useEffect(() => {
    if (selectedCause && selectedCause.items && selectedCause.items.length > 0) {
      setActiveTab(ActiveTabEnum.ITEMS);
    } else if (selectedCause && selectedCause.vacancies && selectedCause.vacancies.length > 0) {
      setActiveTab(ActiveTabEnum.VOLUNTARY);
    }
  }, [selectedCause?.items, selectedCause?.vacancies]);



  return (
    <>
      <Container.main>
        {selectedCause?.images[0] && (
          <ImageCampaign srcOfImages={selectedCause?.images.map(({ src }) => src) ?? []} />
        )}
        <Container.description>
          <Description.title>{selectedCause?.title.slice(0, 30)}</Description.title>
          <Description.subtitle style={{ minHeight: "210px", alignItems: "flex-start" }}>
            <p >{selectedCause?.description.slice(0, 700)}</p>
          </Description.subtitle>

          <Container.Location>
            <div>
              {selectedCause?.items && selectedCause?.items?.length > 0 || selectedCause?.vacancies && selectedCause?.vacancies?.length > 0 ? (
                <a href='#' onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  e.preventDefault();
                  const targetElement = document.getElementById('ItemAndVoluntary');
                  if (targetElement) {
                    const yOffset = -200;
                    const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                  }
                }}>
                  <button> Ver doações e voluntariados</button></a>
              ) : null}
            </div>
            <div>
              <img src={locality} alt="cidade" />
              <span>{selectedCause?.city} - </span>
              <span>{selectedCause?.state}</span>
            </div>
          </Container.Location>
          {selectedCause?.moneyDonationOptions &&
            <>
              <Container.value>
                <Description.subtitle>
                  Instituição:<Description.text style={{ fontWeight: 600 }}> {selectedCause?.institution?.name}</Description.text>
                </Description.subtitle>

              </Container.value>

              <Container.box>
                <Container.value>
                  <Description.subtitle>Meta:<h5>{(selectedCause?.moneyDonationOptions?.target ? selectedCause.moneyDonationOptions.target / 100 : 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h5></Description.subtitle>

                </Container.value>
                <Container.value>
                  <Description.subtitle>Arrecadado:<h5 style={{ fontWeight: 600 }}> {handleAccumulatedValue}</h5></Description.subtitle>

                </Container.value>
              </Container.box>
              <Container.Progress>
                <span>{selectedCause.progress ?? 0}%</span>
                <div className="progress">
                  <div className="barprogress" style={{ width: `${selectedCause.progress}%` }}></div>
                </div>
              </Container.Progress>
            </>
          }
          <Link to={Url()} target={'_blank'} rel="noreferrer" style={{ width: "100%" }}>
            <Container.button >
              Saiba como apoiar
            </Container.button>
          </Link>

          <Container.box>
            <Container.share onClick={handleShare}>
              <p>Compartilhe esta causa:</p>
              <span>
                <img src={share} alt="gooders" />
                clique aqui
              </span>
            </Container.share>
          </Container.box>
        </Container.description>
      </Container.main>
      {selectedCause?.items && selectedCause?.items?.length > 0 || selectedCause?.vacancies && selectedCause?.vacancies?.length > 0 ? (
        <Container.main style={{ paddingTop: 0, width: "100%" }}>
          <Container.content>
            <Container.wrapper>
              {selectedCause?.items?.length > 0 && (
                <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.ITEMS)}>
                  <Container.subTitle active={activeTab === ActiveTabEnum.ITEMS}>
                    Itens
                  </Container.subTitle>
                </Link>
              )}
              {selectedCause?.vacancies?.length > 0 && (
                <Link to="#" onClick={() => setActiveTab(ActiveTabEnum.VOLUNTARY)}>
                  <Container.subTitle active={activeTab === ActiveTabEnum.VOLUNTARY}>
                    Voluntariado
                  </Container.subTitle>
                </Link>
              )}
            </Container.wrapper>
            <Container.wrapper style={{ width: "100%" }} id="ItemAndVoluntary">
              {activeTab === ActiveTabEnum.ITEMS && selectedCause?.items.length > 0 && (<DonationItems selectedCause={selectedCause} />)}
              {activeTab === ActiveTabEnum.VOLUNTARY && selectedCause?.vacancies?.length > 0 && (<DonationVoluntary selectedCause={selectedCause} />)}
            </Container.wrapper>
            <Link to={Url()} target={'_blank'} rel="noreferrer" style={{ width: "100%" }}>
              <Container.button style={{ width: "250px" }}>
                Saiba como apoiar
              </Container.button>
            </Link>
          </Container.content>

        </Container.main>
      ) : null}

    </>
  )
}
