import { Container } from './styles'
import logo_coloridos from '../../../assets/logo/logo_coloridos.png'
import Burger from '../Burguer'
import { Link } from 'react-router-dom'
import { useEffect } from 'react';

export const Navbar = () => {

  const ScrollToTop = () => {
    useEffect(() => {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

      const handleClick = () => {
        scrollToTop();
      };

      return () => {
        window.removeEventListener('scroll', handleClick);
      };
    }, []);
  }

  return (
    <Container.header>
      <Container.box>
        <Link to="/" onClick={ScrollToTop}><Container.image src={logo_coloridos} alt="logo" /></Link>
        <Container.nav>
          <Burger />
        </Container.nav>
      </Container.box>
    </Container.header>
  )
}
