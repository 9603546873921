import { Container } from './styles'

export const DoGoodDay = () => {
  return (
    <Container.box id="doGoodDay">
      <Container.content>
        <Container.title>
          <h2>Escolha como nos apoiar</h2>
        </Container.title>
        <p>
        O Instituto Ronald McDonald é uma organização social sem fins lucrativos,
        100% mantida pelo apoio de pessoas físicas e empresas que acreditam na missão
        de promover a saúde e bem-estar para crianças, adolescentes e seus familiares,
        contribuindo para aumentar as  chances de cura do câncer infantojuvenil no Brasil.
        </p>
        <p>Escolha sua forma de contribuir para nossas causas e ganhe uma recompensa!</p>
      </Container.content>
    </Container.box>
  )
}
