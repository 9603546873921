import styled from 'styled-components'

const Container__Box = styled.div`
  padding: 8px;
  max-width: 500px;
  font-family: 'Poppins', sans-serif;
`

const Container__Cards = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  border-radius: 8px;
  box-shadow: none;
  transition: all 300ms linear 0s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  &:hover {
    box-shadow: 0px 20px 30px rgb(153 153 153 / 60%);
  }
`

const Container__Image = styled.div`
  margin: 0 8px;
  display: flex;
  justify-content: center;
  img {
    width: 240px;
    height: 160px;
    border-radius: 8px;
    object-fit: cover;
  }
`

const Container__Text = styled.div`
margin: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  gap: 8px;
  height: 170px;
  h3 {
    font-weight: 500;
    line-height: 24px;
    font-size: ${(props) => props.theme.fontSize.base};
  }
  p {
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.smX};
    line-height: 18px;
    color: ${(props) => props.theme.colors.gray};
  }
`

const Container__Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  a {
    width: 100%;
  }
`

const Container__Button = styled.button`
  margin-top: 6px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  padding: 4px 28px;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: all 300ms linear 0s;
  &:hover {
    background: ${(props) => props.theme.colors.lightgray};
    color: ${(props) => props.theme.colors.primary};
  }
`

const Container__Location = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  color: ${(props) => props.theme.colors.gray};
  align-items: center;
  span {
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.smX};
    line-height: 18px;
  }
  img {
  }
`

const Container__Icons = styled.div`
  display: flex;
  gap: 16px;
  img {
    width: 20px;
  }
`

const Container__Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0 8px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgray};
`

const Container__Progress = styled.div`
  margin: 12px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  span {
  font-size: ${props => props.theme.fontSize.md};
  line-height: 22px;
  }
  .progress{
    background: ${props => props.theme.colors.lightgray};
    border-radius: 4px;
    height: 8px;
    width: 100%;
  }
  .barprogress {
    border-radius: 4px;
    height: 8px;
    background: ${props => props.theme.colors.primary};
    border-radius: 40px;
  }
`
export const Container = {
  box: Container__Box,
  cards: Container__Cards,
  image: Container__Image,
  text: Container__Text,
  button: Container__Button,
  footer: Container__Footer,
  location: Container__Location,
  icons: Container__Icons,
  content: Container__Content,
  Progress: Container__Progress,
}

export const Line = styled.div``
