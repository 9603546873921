import { CauseUnit } from '../components/common/CauseUnit'
import { Develop } from '../components/common/Develop'
import { Navbar } from '../components/common/Navbar'

export const Campaign = () => {
  return (
    <div style={{display: "flex", flexFlow: "column", minHeight: "100vh", alignItems: "center", justifyContent: "space-between"}}>
      <Navbar />
      <CauseUnit />
      {/* <Footer /> */}
      <Develop />
    </div>
  )
}
