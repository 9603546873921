import React from 'react'
import { Container } from './styles'
import { Link } from 'react-router-dom'

interface RightNavProps {
  open: boolean
}

const RightNav: React.FC<RightNavProps> = ({ open }) => {


  return (
    <Container.nav open={open}>
      <Container.right open={open}>
        <ul>
          <Link to="/"><li>Home</li></Link>
          <Link to="/causes"><li>Causas</li></Link>
          <li>
            <a href="/#institutional">Institucional</a>
          </li>
          <li>
            <Link to="https://app.gooders.com.br/login" target={'_blank'} rel="noreferrer">Acesso</Link>
          </li>
          <li>
            <Link to="https://gooders.com.br/" target={'_blank'} rel="noreferrer">Gooders</Link>
          </li>
        </ul>
      </Container.right>
    </Container.nav>
  )
}

export default RightNav
