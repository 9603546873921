import styled from 'styled-components'
import theme from '../../../../../themes/theme'

const Container__Pagination = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.gray};
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  label {
    font-size: ${(props) => props.theme.fontSize.md};
  }
  select {
    border: none;
    outline: none;
    padding: 8px;
    color: ${(props) => props.theme.colors.gray};
    font-size: ${(props) => props.theme.fontSize.sm};
  }
  span {
    font-size: ${(props) => props.theme.fontSize.md};
  }
  @media (max-width: ${theme.media.monitor}) {
    justify-content: flex-start;
  }
`

export const Container = {
  Pagination: Container__Pagination
}
