import {
  createBrowserRouter,
  RouterProvider as ReactRouterDomProvider
} from 'react-router-dom'
import { Campaign } from '../../pages/Campaign'
import { Home } from '../../pages/Home'
import { Campaigns } from '../../pages/Campaigns'

export const RouterProvider = () => {
  return <ReactRouterDomProvider router={routerToUser} />
}

const routerToUser = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/causes',
    element: <Campaigns />
  },
  {
    path: '/causes/:path',
    element: <Campaign />
  }
])
