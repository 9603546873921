import styled from 'styled-components'
import theme from '../../../../../themes/theme'

const Container__Categorie = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 32px;
  color: ${(props) => props.theme.colors.gray};
  padding: 0 16px;
  img {
    width: 22px;
  }
  @media (max-width: ${theme.media.laptop}) {
    padding: 16px 0;
    justify-content: flex-start;
  }
`

const Container__Icon = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSize.sm};
  border-bottom: 2px solid
    ${(props) => (props.active ? props.theme.colors.primary : 'transparent')};
  :hover {
    border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  }
`

export const Container = {
  Categorie: Container__Categorie,
  Icon: Container__Icon
}
