import { useState, useEffect } from 'react';
import { ItemOutput, UniqueCampaignOutput } from '../../../../interfaces/InterfaceCampaign';
import { SelectInput } from '../../../SelectInput';
import { Container, Line } from './styles';
import { Link } from 'react-router-dom';

interface DonationItemsProps {
  selectedCause: UniqueCampaignOutput | undefined;
}


export const DonationItems: React.FC<DonationItemsProps> = ({ selectedCause }) => {

  const [selectedItemId, setSelectedItemId] = useState<number | undefined>(undefined);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const itemId = parseInt(event.target.value);
    setSelectedItemId(itemId);
  };
  const selectedItem: ItemOutput | undefined = selectedCause?.items.find((item) => item.id === selectedItemId);

  useEffect(() => {
    if (selectedCause?.items.length) {
      setSelectedItemId(selectedCause.items[0].id);
    }
  }, [selectedCause]);

  return (
    <>
      <Container.Input>
        <Container.Text style={{ width: "100%" }}>
          <SelectInput
            htmlFor="itemId"
            name="itemId"
            id="itemId"
            value={selectedItemId !== undefined ? selectedItemId.toString() : ''}
            onChange={handleSelectChange}
            required
            options={[
              { value: '', label: '' },
              ...(selectedCause?.items.map((item) => ({
                value: item.id.toString(),
                label: item.name,
              })) || []),
            ]}
          >
            Objeto de doação
          </SelectInput>
          <Container.Address style={{ marginTop: "16px" }} >
            <span>Informações </span>
            Local de entrega: {selectedItem?.address}
            <Line className="line" />
            Item: {selectedItem?.quantity}
            <p>Itens Doados: {selectedItem?.donated} </p>
          </Container.Address>
        </Container.Text>
      </Container.Input>

    </>
  )
};
