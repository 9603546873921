import styled from 'styled-components'
import theme from '../../../themes/theme'

const Container__Main = styled.div`
  padding: 120px 80px 48px;
  width: 100%;
  display: flex;
  gap: 16px;
  height: 100%;
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.background};
  border-radius: 8px;
  @media (max-width:${theme.media.monitorSmall}) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 150px 20px 16px;}
`

const Container__Img = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 50%;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Container__Description = styled.div`
  width: 50%;
  min-height: 100%;
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width:${theme.media.monitorSmall}){
  width: 100%;
  }
`

const Container__Button = styled.button`
  background:  ${props => props.theme.colors.primary};
  width: 100%;
  padding: 10px 0;
  width: 100%;
  border: solid 1px lightgrey;
  border-radius: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  transition: all 300ms linear 0s;
  &:hover {
    box-shadow: 0px 20px 30px rgb(153 153 153 / 20%);
    background: white;
    color:  ${props => props.theme.colors.primary};
  }
`

const Container__Location = styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between;
  gap: 10px;
  color: ${props => props.theme.colors.gray};
  align-items: center;
  img {
    padding-right: 10px;
  }
  span {
  font-size: ${props => props.theme.fontSize.smX};
  line-height: 18px;
  }
  button {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    padding: 4px 16px;
    border-radius: 4px;
    background: ${props => props.theme.colors.white};
    font-weight: 700;
    transition: all 0.5s;
  }
  button:hover {
    border: 1px solid ${props => props.theme.colors.gray};
    color: ${props => props.theme.colors.gray};
  }
  @media (max-width:${theme.media. mobileSmall}) {
  flex-direction: column;
  }
`;


const Container__Box = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width:${theme.media.tablet}) {
  flex-direction: column;

  }
`;

const Container__Value = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.colors.dark};
  h4 {
  font-size: ${props => props.theme.fontSize.x};
  line-height: 32px;
  }
  h3 {
  font-weight: 600;
  font-size: ${props => props.theme.fontSize.l};
  line-height: 44px;
  }
`;

const Container__Share = styled.div`
  flex: 3;
  display: flex;
  gap: 16px;
  padding: 17px 0;
  font-weight: 500;
  cursor: pointer;
  span {
    border-bottom: 2px solid ${props => props.theme.colors.hoverBlue};
    color: ${props => props.theme.colors.hoverBlue};
  img {
    padding-right: 8px;
  }
  }
  @media (max-width:${theme.media.mobileSmall}) {
    flex-direction: column;
    width: 100%;
  }
`;


const Container__Progress = styled.div`
  margin: 6px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  span {
  font-size: ${props => props.theme.fontSize.md};
  line-height: 22px;
  }
  .progress{
    background: ${props => props.theme.colors.lightgray};
    border-radius: 4px;
    height: 8px;
    width: 100%;
  }
  .barprogress {
    border-radius: 4px;
    height: 8px;
    background: ${props => props.theme.colors.primary};
    border-radius: 40px;
  }
`


const Container__Content= styled.div`
  width: 100%;
  min-height: 100%;
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width:${theme.media.monitorSmall}){
  width: 100%;
  }
`
const Container__Wrapper = styled.div`
	display: flex;
	gap: 24px;
	padding-top: 12px;
`;


const Container__SubTitle = styled.p<{ active?: boolean }>`
font-weight: ${props => props.active && "700"};
border-bottom: ${props => props.active && "1px solid gray"};
@media (max-width: ${theme.media.mobileSmall}) {
  font-size: ${props => props.theme.fontSize.sm};
}



`;

export const Container = {
  main: Container__Main,
  img: Container__Img,
  description: Container__Description,
  button: Container__Button,
  Location: Container__Location,
  value: Container__Value,
	box: Container__Box,
  share: Container__Share,
  Progress: Container__Progress,
  content: Container__Content,
  wrapper: Container__Wrapper,
  subTitle: Container__SubTitle,
}


const Image__Event = styled.div`
  width: 100%;
`

const Image__Cards = styled.img`
  min-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  max-width: 550px;
  max-height: 300px;
  @media (max-width: 768px) {
    width: 100%;
    min-width: 80%;
  }
`

export const Image = {
  event: Image__Event,
  cards: Image__Cards
}

const Description__Title = styled.h2`
  font-weight: 600;
  font-size: ${props => props.theme.fontSize.xll};
  line-height: 44px;
  color: ${props => props.theme.colors.dark};
  padding-bottom: 8px;
`;

const Description__SubTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7786;
  h5 {
    padding-left: 8px;
  }
  p {
  color: ${props => props.theme.colors.gray};
  font-size: ${props => props.theme.fontSize.sm};
  }
`;

const Description__Text = styled.div`
  line-height: 32px;
  font-size: ${props => props.theme.fontSize.base};
  color: #4E545C;
  padding-left: 8px;
  @media (max-width: ${theme.media.mobileSmall}) {
    font-size: ${props => props.theme.fontSize.sm};
  }
`

export const Description = {
  title: Description__Title,
  subtitle: Description__SubTitle,
  text: Description__Text
}







