import { Container, Title, SocialIcon } from './styles'
import IonIcon from '@reacticons/ionicons'
import { Link } from 'react-router-dom'
import { ModalDonation } from '../Modal/ModalDonation'
import { ModalPayment } from '../Modal/ModalPayment'
import { ModalVoluntary } from '../Modal/ModalVoluntary'
import { useState } from 'react'
// import { Payload } from "../../../CauseUnit/index";

interface Props {
  open: boolean
  onClose: () => void
  selectedCause?: {
    id: string
    title: string
    image: string
    summary: string
  }

  // cause: Payload | null | undefined;
}

export const LayoutModal = ({ open, onClose, selectedCause }: Props) => {
  const [activeTab, setActiveTab] = useState('donation')
  if (!open) return null

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName)
  }

  return (
    <Container.main onClick={onClose}>
      <Container.modal
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Container.content>
          <SocialIcon onClick={onClose}>
            <IonIcon name="close-outline"></IonIcon>
          </SocialIcon>
          <Container.title>
            <Link to="#" onClick={() => handleTabClick('voluntary')}>
              <Title.voluntary>Voluntário</Title.voluntary>
            </Link>
            <Link to="#" onClick={() => handleTabClick('donation')}>
              <Title.pay>Doação</Title.pay>
            </Link>
            <Link to="#" onClick={() => handleTabClick('payment')}>
              <Title.donate>Pagamento</Title.donate>
            </Link>
          </Container.title>
          <Container.border>
            {activeTab === 'donation' && (
              <ModalDonation cause={selectedCause} />
            )}
            {activeTab === 'voluntary' && (
              <ModalVoluntary cause={selectedCause} />
            )}
            {activeTab === 'payment' && <ModalPayment />}
          </Container.border>
        </Container.content>
      </Container.modal>
    </Container.main>
  )
}
