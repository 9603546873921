import styled from 'styled-components'

interface StyledBurgerProps {
  open: boolean
}

const Container__Nav = styled.div<StyledBurgerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  @media (max-width: 1024px) {
    margin: 0;
    justify-content: flex-start;
    flex-direction: column;
    background: white;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    height: auto;
    width: 75%;
    height: 100vh;
    top: 0px;
    right: 0%;
    padding: 50px;
    transition: transform 0.3s ease-in-out;
  }
`

const Container__Right = styled.nav<StyledBurgerProps>`
  display: flex;
  gap: 25px;
  color: black;

  ul {
    display: flex;
    gap: 40px;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  li {
    cursor: pointer;
    &:hover {
      color: #f49642;
    }
    &:nth-of-type(2) {
      background: #DA1A00;
      color: white;
      padding: 30px;
      width: 100%;
      height: 100%;
      &:hover {
        background: #f49642;
        color: #DA1A00;
      }
    }
  }

  @media (max-width: 1024px) {
    margin: 20px 0;
    width: 100%;
    text-align: center;
    ul {
      flex-direction: column;
    }
    li {
      color: black;
      font-size: 24px;
      &:hover {
        color: gray;
      }
    }
  }
`

export const Container = {
  nav: Container__Nav,
  right: Container__Right
}
