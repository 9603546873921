import { Cause } from '../..'
import { Container } from './styles'

interface Props {
  totalCount: number
  pageSize: number
  startIndex: number
  pagedCauses: Cause[]
  causes: Cause[] | undefined
  pageSizeOptions: number[]
  handlePageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: number
}

export const PaginationListCause: React.FC<Props> = ({
  pageSize,
  pagedCauses,
  pageSizeOptions,
  handlePageSizeChange,
}) => {
  return (
    <Container.Pagination>
      <label>
        Exibir:
        <select value={pageSize} onChange={handlePageSizeChange}>
          {pageSizeOptions.map((option) => (
            <option key={option} value={option}>
              {option} por página
            </option>
          ))}
        </select>
      </label>
      <span>{`${pagedCauses.length} Campanhas`}</span>
    </Container.Pagination>
  )
}
