import styled from 'styled-components'

const Container__Input = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const Container__Text = styled.div`
  display: flex;
  width: 60%;
  padding: 30px 0;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
`
const Container__Image = styled.div`
  position: relative;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  p {
    position: absolute;
    bottom: 30px;
    left: 0px;
    background: rgba(255, 255, 255, 0.7);
    padding: 8px 0;
    width: 100%;
    border-radius: 4px;
    text-align: center;
  }
  span {
    padding: 0 8px;
  }
`

export const Container = {
  input: Container__Input,
  text: Container__Text,
  image: Container__Image
}

const Input__donate = styled.div`
  width: 90%;
  position: relative;
  input {
    width: 100%;
    padding: 15px;
    border: 1px solid black;
    background: white;
    outline: none;
    font-size: 12px;
  }
  p {
    position: absolute;
    top: -12px;
    left: 5px;
    padding: 5px;
    pointer-events: none;
    background: white;
    font-size: 12px;
    font-weight: 600;
  }
`
const Input__hours = styled(Input__donate)``

export const Input = {
  donate: Input__donate,
  hours: Input__hours
}

export const Button = styled.button`
  padding: 10px;
  width: 95%;
  color: white;
  background: black;
  margin-bottom: 20px;
  transition: all 300ms linear 0s;
  &:hover {
    background: white;
    color: black;
  }
`

export const Logo = styled.div`
  position: absolute;
  top: 32px;
  left: 13px;
  background: blue;
  padding: 5px;
  border-radius: 0 0 4px 4px;
  img {
    width: 20px;
    height: auto;
  }
`
