import { Container } from './styles'

export const ModalPayment: React.FC = () => {
  return (
    <>
      <Container.main>
        <p>
          Aguardo autorização do meio de pagamento, para saber o que é possivel
          estilizar
        </p>
      </Container.main>
    </>
  )
}
